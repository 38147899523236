import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src74448501/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { FormProps } from '../../components/FormProps';
export const name = 'ProfileForm';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  name,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "profile-form"
    }}>{`Profile Form`}</h2>


    <h2>{name}</h2>
    <FormProps fields={[{
      name: 'username',
      type: 'text'
    }, {
      name: 'email',
      type: 'text'
    }, {
      name: 'displayName',
      type: 'text'
    }, {
      name: 'enabled',
      type: 'checkbox'
    }, {
      name: 'spaceAdmin',
      type: 'checkbox'
    }, {
      name: 'password',
      type: 'password'
    }, {
      name: 'passwordConfirmation',
      type: 'password'
    }, {
      name: 'changePassword',
      type: 'checkbox'
    }, {
      name: 'allowedIps',
      type: 'text'
    }, {
      name: 'preferredLocale',
      type: 'text'
    }, {
      name: 'timezone',
      type: 'text'
    }, {
      name: 'attributesMap',
      type: 'attributes'
    }, {
      name: 'profileAttributesMap',
      type: 'attributes'
    }, {
      name: 'memberships',
      type: 'team-multi'
    }]} formOptions={[]} dataSources={[{
      name: 'locales',
      type: 'locales[]'
    }, {
      name: 'timezones',
      type: 'timezones[]'
    }, {
      name: 'profile',
      type: 'profile'
    }, {
      name: 'attributeDefinitions',
      type: 'attributeDefinitions[]'
    }, {
      name: 'profileAttributeDefinitions',
      type: 'profileAttributeDefinitions[]'
    }]} mdxType="FormProps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      